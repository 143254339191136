// Generated by purs version 0.15.9
import * as Control_Applicative from "../Control.Applicative/index.js";
import * as Control_Bind from "../Control.Bind/index.js";
import * as Data_Array from "../Data.Array/index.js";
import * as Data_Either from "../Data.Either/index.js";
import * as Data_Functor from "../Data.Functor/index.js";
import * as Data_List from "../Data.List/index.js";
import * as Data_Maybe from "../Data.Maybe/index.js";
import * as Data_Number from "../Data.Number/index.js";
import * as Data_Show from "../Data.Show/index.js";
import * as Data_String_CodeUnits from "../Data.String.CodeUnits/index.js";
import * as Data_Unfoldable from "../Data.Unfoldable/index.js";
import * as Data_Unit from "../Data.Unit/index.js";
import * as Parsing from "../Parsing/index.js";
import * as Parsing_String from "../Parsing.String/index.js";
import * as Parsing_String_Basic from "../Parsing.String.Basic/index.js";
var fromJust = /* #__PURE__ */ Data_Maybe.fromJust();
var bind = /* #__PURE__ */ Control_Bind.bind(Parsing.bindParserT);
var map = /* #__PURE__ */ Data_Functor.map(Parsing.functorParserT);
var some = /* #__PURE__ */ Data_Array.some(Parsing.alternativeParserT)(Parsing.lazyParserT);
var pure = /* #__PURE__ */ Control_Applicative.pure(Parsing.applicativeParserT);
var many = /* #__PURE__ */ Data_Array.many(Parsing.alternativeParserT)(Parsing.lazyParserT);
var discard = /* #__PURE__ */ Control_Bind.discard(Control_Bind.discardUnit)(Parsing.bindParserT);
var show = /* #__PURE__ */ Data_Show.show(Parsing.showParseError);
var unwrapEither = function (v) {
    if (v instanceof Data_Either.Left) {
        return {
            left: [ v.value0 ],
            right: [  ]
        };
    };
    if (v instanceof Data_Either.Right) {
        return {
            left: [  ],
            right: [ v.value0 ]
        };
    };
    throw new Error("Failed pattern match at VTT (line 91, column 1 - line 91, column 62): " + [ v.constructor.name ]);
};
var time = /* #__PURE__ */ (function () {
    var toNum = function (s) {
        return fromJust(Data_Number.fromString(Data_String_CodeUnits.fromCharArray(s)));
    };
    return bind(map(toNum)(some(Parsing_String_Basic.digit)))(function (minutes) {
        return bind(Parsing_String["char"](":"))(function () {
            return bind(map(toNum)(some(Parsing_String_Basic.digit)))(function (seconds) {
                return bind(Parsing_String["char"]("."))(function () {
                    return bind(map(toNum)(some(Parsing_String_Basic.digit)))(function (milliseconds) {
                        return pure((minutes * 60.0 + seconds) * 1000.0 + milliseconds);
                    });
                });
            });
        });
    });
})();
var listToStr = /* #__PURE__ */ (function () {
    var $24 = Data_List.toUnfoldable(Data_Unfoldable.unfoldableArray);
    return function ($25) {
        return Data_String_CodeUnits.fromCharArray($24($25));
    };
})();
var line = /* #__PURE__ */ map(Data_String_CodeUnits.fromCharArray)(/* #__PURE__ */ many(/* #__PURE__ */ Parsing_String_Basic.noneOf([ "\x0a" ])));
var vttLine = /* #__PURE__ */ bind(Parsing_String_Basic.skipSpaces)(function () {
    return bind(time)(function (fromTime_) {
        return bind(Parsing_String_Basic.skipSpaces)(function () {
            return bind(Parsing_String.string("-->"))(function () {
                return bind(Parsing_String_Basic.skipSpaces)(function () {
                    return bind(time)(function (toTime_) {
                        return bind(Parsing_String_Basic.skipSpaces)(function () {
                            return bind(line)(function (message_) {
                                return bind(Parsing_String_Basic.skipSpaces)(function () {
                                    return pure({
                                        fromTime: fromTime_,
                                        toTime: toTime_,
                                        message: message_
                                    });
                                });
                            });
                        });
                    });
                });
            });
        });
    });
});
var hi = "Hi";
var firstLine = /* #__PURE__ */ bind(/* #__PURE__ */ Parsing_String.string("WEBVTT"))(function (v) {
    return pure(Data_Unit.unit);
});
var vtt = /* #__PURE__ */ bind(Parsing_String_Basic.skipSpaces)(function () {
    return bind(firstLine)(function () {
        return bind(Parsing_String_Basic.skipSpaces)(function () {
            return bind(many(vttLine))(function (lines_) {
                return discard(Parsing_String.eof)(function () {
                    return pure({
                        lines: lines_
                    });
                });
            });
        });
    });
});
var parse = function (input) {
    var v = Parsing.runParser(input)(vtt);
    if (v instanceof Data_Either.Right) {
        return new Data_Either.Right(v.value0);
    };
    if (v instanceof Data_Either.Left) {
        return new Data_Either.Left(show(v.value0));
    };
    throw new Error("Failed pattern match at VTT (line 84, column 15 - line 86, column 30): " + [ v.constructor.name ]);
};
var example = "WEBVTT\x0a\x0a00:14.848 --> 00:16.725\x0a\uc790\uc720\uc2dc\uac04 \ub9ce\uc774 \ub4dc\ub838\uc2b5\ub2c8\ub2e4.";
export {
    firstLine,
    vttLine,
    time,
    listToStr,
    line,
    example,
    vtt,
    parse,
    unwrapEither,
    hi
};
